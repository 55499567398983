<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) Count the number of atoms of each type in the products AND reactants in the following
        <b>unbalanced</b> reaction.
      </p>
      <p class="mb-2 pl-12">
        <chemical-latex :content="unbalancedReaction" />
      </p>

      <v-simple-table style="width: 300px" class="mb-5">
        <thead>
          <tr>
            <th style="font-size: 1em">
              <stemble-latex content="$\text{Atom}$" />
            </th>
            <th style="font-size: 1em">
              <stemble-latex content="$\text{Reactants}$" />
            </th>
            <th style="font-size: 1em">
              <stemble-latex content="$\text{Products}$" />
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <chemical-latex :content="atom1" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.XreactUB"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.XprodUB"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{O}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.OreactUB"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.OprodUB"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{H}$" />
            </td>

            <td>
              <calculation-input
                v-model="inputs.HreactUB"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.HprodUB"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-4">
        b) Count the number of atoms of each type in the products AND reactants for the
        <b>balanced</b> form of the reaction.
      </p>

      <p class="mb-2 pl-12">
        <chemical-latex :content="balancedReaction" />
      </p>

      <v-simple-table style="width: 300px">
        <thead>
          <tr>
            <th style="font-size: 1em">
              <stemble-latex content="$\text{Atom}$" />
            </th>
            <th style="font-size: 1em">
              <stemble-latex content="$\text{Reactants}$" />
            </th>
            <th style="font-size: 1em">
              <stemble-latex content="$\text{Products}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <chemical-latex :content="atom1" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.XreactBal"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.XprodBal"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{O}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.OreactBal"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.OprodBal"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{H}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.HreactBal"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.HprodBal"
                class="pb-2 pt-2 centered-input"
                style="width: 65px"
                dense
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question361',
  components: {
    ChemicalLatex,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        XreactUB: null,
        OreactUB: null,
        HreactUB: null,
        XprodUB: null,
        OprodUB: null,
        HprodUB: null,
        XreactBal: null,
        OreactBal: null,
        HreactBal: null,
        XprodBal: null,
        OprodBal: null,
        HprodBal: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    atom1() {
      if (this.versionVariable.value === 1) {
        return 'P';
      } else if (this.versionVariable.value === 2) {
        return 'P';
      } else if (this.versionVariable.value === 3) {
        return 'Cl';
      } else {
        return 'ERROR';
      }
    },
    unbalancedReaction() {
      if (this.versionVariable.value === 1) {
        return 'P4O6 + H2O -> H3PO3';
      } else if (this.versionVariable.value === 2) {
        return 'P4O10 + H2O -> H3PO4';
      } else if (this.versionVariable.value === 3) {
        return 'Cl2O7 + H2O -> HClO4';
      } else {
        return 'ERROR';
      }
    },
    balancedReaction() {
      if (this.versionVariable.value === 1) {
        return 'P4O6 + 6 H2O -> 4 H3PO3';
      } else if (this.versionVariable.value === 2) {
        return 'P4O10 + 6 H2O -> 4 H3PO4';
      } else if (this.versionVariable.value === 3) {
        return 'Cl2O7 + H2O -> 2 HClO4';
      } else {
        return 'ERROR';
      }
    },
  },
};
</script>

<style>
.centered-input input {
  text-align: center;
}
</style>
